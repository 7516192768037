import { transitions } from "../transactions/transactionDotsProcessPurchase";
import { CATEGORY_ACCESSORY, CATEGORY_GOWNS, CATEGORY_OCCASIONS, CATEGORY_SHOES } from "./types";

export const getFiltersOption = (availableFilters, validQueryParams) => {
  let filters = availableFilters;
  switch (validQueryParams?.pub_productType) {
    case CATEGORY_SHOES:
      filters = availableFilters.filter(st => st.key === 'size' || st.key === 'color');
      break;
    case CATEGORY_GOWNS:
      filters = availableFilters.filter(st => st.key === 'size' || st.key === 'color' || st.key === 'style' || st.key === 'material');
      break;
    case CATEGORY_ACCESSORY:
      filters = availableFilters.filter(st => st.key === 'category');
      break;
    case CATEGORY_OCCASIONS:
      filters = availableFilters.filter(st => st.key === 'category' || st.key === 'size' || st.key === 'color');
      break;
    default:
      break;
  };
  return filters;
};


export function capitalizeEachWord(text) {
  // Check if the string is empty or undefined
  if (typeof text !== 'string' || text.length === 0) {
    return ''; // Return an empty string if input is invalid
  }

  // Split the string into an array of words
  const words = text.split(' ');

  // Capitalize the first letter of each word and join them back
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
}


export const getUserDetails = user => {
  let profileImage = null;
  if (!!user?.id && user?.profileImage?.attributes?.variants) {
    if (user?.profileImage?.attributes?.variants?.default) {
      profileImage = user?.profileImage?.attributes?.variants?.default?.url;
    } else {
      profileImage = user?.profileImage?.attributes?.variants['square-small2x']?.url;
    }
  } else {
    profileImage = user?.attributes?.profile?.publicData?.picture;
  }
  const email =
    !!user?.id && user?.attributes?.email
      ? user?.attributes?.email
      : user?.attributes?.profile?.publicData?.email;
  const fullName = user?.attributes?.profile?.publicData?.fullName;
  const id = user?.id && user?.id?.uuid;
  const firstName = user?.attributes?.profile?.firstName;
  const lastName = user?.attributes?.profile?.lastName;
  const displayName = user?.attributes?.profile?.displayName;

  return {
    fullName,
    profileImage,
    email,
    id,
    firstName,
    lastName,
    displayName
  };
};


export function getFileExtension(filePath) {
  const extIndex = filePath.lastIndexOf('.');
  return extIndex > 0 ? filePath.substring(extIndex + 1) : '';
};

export const soldPurchaseTransactions = [
  transitions.CONFIRM_PAYMENT,
  transitions.COUNTER_OFFER_ACCEPTED_BY_CUSTOMER,
  transitions.OFFER_ACCEPTED_BY_PROVIDER,
  transitions.MARK_RECEIVED,
  transitions.REVIEW_1_BY_CUSTOMER,
  transitions.REVIEW_1_BY_PROVIDER,
  transitions.REVIEW_2_BY_CUSTOMER,
  transitions.REVIEW_2_BY_PROVIDER,
  transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
  transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  transitions.EXPIRE_REVIEW_PERIOD,
  transitions.AUTO_COMPLETE,
  transitions.SHIPPING_LABEL_CREATE,
  transitions.ORDER_CANCEL_BY_CUSTOMER,
  transitions.ORDER_CANCEL_BY_PROVIDER,
  transitions.CANCEL_BY_OPERATOR,
  transitions.OPERATOR_MARK_SHIPPED,
  transitions.OPERATOR_MARK_DELIVERED,
  transitions.ORDER_PURCHASED,
  transitions.OPERATOR_CANCEL_WITH_REFUND_AFTER_RECEIVED,
  transitions.OPERATOR_CANCEL_WITHOUT_REFUND_AFTER_RECEIVED,
  transitions.OPERATOR_CANCEL_WITH_REFUND_AFTER_DELIVERED,
  transitions.OPERATOR_CANCEL_WITHOUT_REFUND_AFTER_DELIVERED,
  transitions.OPERATOR_CANCEL_WITH_REFUND_AFTER_ORDER_SHIPPED,
  transitions.OPERATOR_CANCEL_WITHOUT_REFUND_AFTER_ORDER_SHIPPED,
  transitions.OPERATOR_CANCEL_WITH_REFUND_AFTER_SHIPPING_LABEL_CREATED,
  transitions.OPERATOR_CANCEL_WITHOUT_REFUND_AFTER_SHIPPING_LABEL_CREATED,
  transitions.OPERATOR_CANCEL_WITH_REFUND_AFTER_PURCHASED,
  transitions.OPERATOR_CANCEL_WITHOUT_REFUND_AFTER_PURCHASED,
  transitions.OPERATOR_CANCEL_WITH_REFUND,
  transitions.OPERATOR_CANCEL_WITHOUT_REFUND,

  'transition/auto-payment',
  'transition/confirm-manual-payment',
  'transition/confirm-payment',
  'transition/select-shipping-option',
  'transition/create-label',
  'transition/pay-shipping-cost',
  'transition/schedule-pickup',
  'transition/mark-delivered',
  'transition/mark-delivered-after-pickup',
  'transition/mark-received-from-purchased',
  'transition/mark-received',
  'transition/auto-mark-received',
  'transition/auto-complete',

];

const downloadFile = (fileUrl, fileName) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFiles = (url, name) => {
  const pdfFileUrl = url;
  const pdfFileName = name;
  return downloadFile(pdfFileUrl, pdfFileName);
};


export const inProcessingTransactions = [
  transitions.INQUIRE_MESSAGE,
  transitions.MAKE_AN_OFFER_BY_CUSTOMER,
  transitions.OFFER_COUNTERED_BY_PROVIDER,
  transitions.COUNTER_BY_CUSTOMER_2,
  transitions.OFFER_REJECTED_BY_PROVIDER,
  transitions.COUNTER_OFFER_REJECTED_BY_CUSTOMER,
  transitions.OFFER_EXPIRE,
  transitions.COUNTER_OFFER_EXPIRE,
  transitions.REQUEST_PAYMENT,
  transitions.OPERATOR_CANCEL_WITH_REFUND_AFTER_OFFER,
  transitions.OPERATOR_CANCEL_WITHOUT_REFUND_AFTER_OFFER,
  transitions.OPERATOR_CANCEL_WITH_REFUND_AFTER_COUNTER_OFFER,
  transitions.OPERATOR_CANCEL_WITHOUT_REFUND_AFTER_COUNTER_OFFER,
  transitions.AUTO_CANCEL,

  'transition/provider-decline',
  'transition/provider-offer',
  'transition/customer-decline',
  'transition/customer-offer',
  'transition/customer-offer-expired',
  'transition/provider-offer-expired',
  'transition/enquire',
  'transition/request-payment-after-enquiry',
  'transition/provider-accept',
  'transition/customer-accept',
  'transition/mark-pending-payment',
  'transition/expire',
  'transition/initiate-manual-payment',
  'transition/expire-auto-payment',
  'transition/expire-manual-payment',
  'transition/pay',
  'transition/buy-now',
];

export const inOfferTransactions = [
  transitions.MAKE_AN_OFFER_BY_CUSTOMER,
  transitions.OFFER_COUNTERED_BY_PROVIDER,
  transitions.COUNTER_BY_CUSTOMER_2,
  transitions.COUNTER_OFFER_REJECTED_BY_CUSTOMER,
  transitions.OFFER_EXPIRE,
  transitions.COUNTER_OFFER_EXPIRE
];

export const getLabel = (category, key) => {
  const label = category.find(c => c.option === key);
  return label ? label.label : key;
};


const addHoursToDate = (date, hours) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hours);
  return newDate;
};

const add14HoursToCurrentDate = () => {
  const currentDate = new Date();
  return addHoursToDate(currentDate, 14);
};

// const addMinutesToDate = (date, minutes) => {
//   const newDate = new Date(date);
//   newDate.setMinutes(newDate.getMinutes() + minutes);
//   return newDate;
// };

// const add5MinutesToCurrentDate = () => {
//   const currentDate = new Date();
//   return addMinutesToDate(currentDate, 5);
// };

export const getTimestampAfterAdding7Hours = () => {
  const newDate = add14HoursToCurrentDate();
  console.log(newDate, newDate.getTime(), '((( ))) => newDate');
  return newDate.getTime();
};


export const getTimestampAfterAdding14Hours = () => {
  const millisecondsInAnHour = 60 * 60 * 1000; // 1 hour in milliseconds
  const currentTimestamp = Date.now();
  const timestampAfter7Hours = currentTimestamp + 14 * millisecondsInAnHour;
  return Math.floor(timestampAfter7Hours / 1000);
};

export const getTimestampAfterAdding2Minutes = () => {
  const millisecondsInAMinute = 60 * 1000; // 1 minute in milliseconds
  const currentTimestamp = Date.now();
  const timestampAfter2Minutes = currentTimestamp + 5 * millisecondsInAMinute;
  return Math.floor(timestampAfter2Minutes / 1000);
};

export const paymentDoneTransitions = [
  transitions.CONFIRM_PAYMENT,
  transitions.OFFER_ACCEPTED_BY_PROVIDER,
  transitions.COUNTER_OFFER_ACCEPTED_BY_CUSTOMER,
  transitions.SHIPPING_LABEL_CREATE,
  transitions.OPERATOR_MARK_DELIVERED,
  transitions.MARK_RECEIVED,
  transitions.AUTO_COMPLETE,
  transitions.CANCEL_BY_OPERATOR,
  transitions.OFFER_REJECT_BY_OPERATOR,
  transitions.ORDER_PURCHASED,
  transitions.OPERATOR_MARK_SHIPPED,
];